import React from "react";
import BasketComponent from "../Components/BasketComponent/BasketComponent";

function Basket(){
    return(
        <div>
            <BasketComponent />
        </div>
    )
}

export default Basket;