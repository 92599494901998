import React from "react";
import FullCardComponent from "../Components/FullCard/FullCard";

function FullCard(){
    return(
        <>
            <FullCardComponent/>
        </>
    )
}

export default FullCard